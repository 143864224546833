import React from 'react';
import { Card } from '@blueprintjs/core';

import logoRegionSud from '../../../images/logo_regionSud.png';
import logoCCIR from '../../../images/logo_CCIR.jpg';
import logoDreal from '../../../images/logo_dreal.jpg';
import logoEpf from '../../../images/logo_EPF-PACA.png';
import logoMC from '../../../images/logo_MakinaCorpus.png';
import logoRisingSUD from '../../../images/logo_risingSUD.png';
import logoCMAR from '../../../images/logo_cmar.png';

const logosPath = [
  {
    name: 'ccir',
    path: 'http://www.paca.cci.fr/',
    logo: logoCCIR,
  },
  {
    name: 'dreal',
    path: 'http://www.paca.developpement-durable.gouv.fr/',
    logo: logoDreal,
  },
  {
    name: 'epf',
    path: 'http://www.epfpaca.com/',
    logo: logoEpf,
  },
  {
    name: 'RegionSud',
    path: 'https://www.maregionsud.fr/',
    logo: logoRegionSud,
  },
  {
    name: 'MakinaCorpus',
    path: 'https://www.makina-corpus.com/',
    logo: logoMC,
  },
  {
    name: 'RisingSUD',
    path: 'https://www.risingsud.fr/',
    logo: logoRisingSUD,
  },
  {
    name: 'CMAR',
    path: 'https://www.cmar-paca.fr/',
    logo: logoCMAR,
  },
];

const PartnerPage = () => (
  <Card>
    <h3>Bienvenue</h3>
    <p>
      L'application SUD Foncier Eco vous permet de contribuer à l'amélioration
      des données représentées via un module de signalement. Ce module vous
      permettra de remonter des anomalies localisées aux administrateurs.
    </p>
    <p>
      Pour plus d'information sur le fonctionnement de l'application, vous
      pouvez consulter le{' '}
      <a
        href='http://sudfonciereco.maregionsud.fr/comprendre/'
        target='_blank'
        rel='noopener noreferrer'
      >
        manuel d'aide
      </a>
      .
    </p>
    <h3>Partenaires</h3>
    <div className='partner-logo-container'>
      {logosPath.map(({ name, path, logo }) => (
        <a key={name} href={path} target='_blank' rel='noopener noreferrer'>
          <img className='partner-logo' src={logo} label={name} alt={name} />
        </a>
      ))}
    </div>
  </Card>
);

export default PartnerPage;
